.product__style {
    padding: 50px 0 0.75rem 0;
    min-height: 60vh;
    .product__style__title {
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        z-index: 2;
    }

    .product__style__content {
        .filter__panel {
            padding-left: 0;
            .section__simple {
                padding-bottom: 1rem;
            }
            .section__content {
                padding: 0.5rem 0;
            }
            .related__category {
                flex-direction: column;
                .hadchild {
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    padding: 0.5rem 0.625rem 0.5rem 0.75rem;
                    position: relative;
                    font-size: 1rem;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                    line-height: 1rem;
                    max-height: 3rem;
                    word-wrap: break-word;
                    svg {
                        position: absolute;
                        left: 0.5rem;
                        width: 16px;
                        height: 16px;
                        margin-top: 1px;
                        visibility: hidden;
                    }
                    span {
                        padding-left: 16px;
                        display: block;
                    }
                    &:active,
                    &:hover {
                        outline: none;
                        color: #09522f;
                        font-weight: 700;
                        svg {
                            visibility: visible;
                        }
                    }
                    &.active {
                        svg {
                            visibility: visible;
                        }
                        outline: none;
                        color: #09522f;
                        font-weight: 700;
                    }
                }
            }
        }
        .product__result {
            .result__wapper{
                min-height: 300px;
                margin-top: 0.75rem;
                .detail__content {
                    min-height: 50vh;
                    padding: 1rem;
                    margin-bottom: 1rem;
                    background-color: #fff;
                    overflow: hidden;
                }
                .result__content {
                    padding-top: 0.625rem;
                    margin: -0.3125rem;
                }
                .result__item {
                    margin: 0.3125rem 0;
                    padding: 0 0.3125rem;
                    .product__item {
                        box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.1);
                        border-radius: 0.125rem;
                        background-color: #fff;
                        a:hover{
                            border-color: #09522f;
                        }
                    }
                }
                .pagination__content {
                    margin:  2.5rem -0.3125rem 2.5rem -0.3125rem;
                    padding: 0 0.3125rem;
                }
            }
        }
    }
}

@include responsive("md") {
    .product__style {
        padding: 1rem 0 0 0;
        .product__style__title {
            position: sticky;
            top: 0px;
            z-index: 2;
        }
        .product__style__content {
            .product__result {
                .result__wapper{
                    margin-top: 0rem; 
                }
            }
        }
    }
}

@include responsive("lg") {
    .product__style {
        .product__style__content {
            .product__result {
                // .detail__content {
                //     margin-left: 0.3125rem;
                // }
                .result__content {
                    padding-top: 0.625rem;
                    margin: -0.3125rem -0.3125rem -0.3125rem -0.3125rem;
                }
            }
        }
    }
}
@include responsive("xl") {
    .product__style {
        .product__style__content {
            .filter__panel{
                width: 21%;
            }
            .product__result {
                width: 79%;
            }
        }
    }
}
