.carousel{
    .carousel-control-next, .carousel-control-prev {
        width: 2rem;
        .carousel-control-next-icon, .carousel-control-prev-icon {
            background-color: rgba(0, 0, 0, 0.18);
            height: 4rem;
        }
        opacity: 0;
    }
    &:hover{
        .carousel-control-next, .carousel-control-prev {
            opacity: 0.5;
        }
    }
   
    .carousel-control-next:hover, 
    .carousel-control-prev:hover {
        opacity: 0.9;
    }

    .carousel-indicators {
        button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid hsla(0,0%,53.7%,.4);
            background-color: hsla(0,0%,100%,.4);
            margin: 0 6px;
            opacity: 1;
            &.active {
                background-color: #fb811e;
            }
        }
    }
}
