.product__detail {
    .product__style__content {
        margin-top: 0;
        .poduct__detail__top {
            background-color: #fff;
            .poduct__detail__image {
                padding-top: 0.75rem;
                .large__image {
                    img {
                        width: 100%;
                        height: auto;
                        min-height: 100px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .image__swiper {
                    height: auto;
                    min-height: 10px;
                    padding: 10px 0;
                    .swiper-slide {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 1 / 1;
                        border: 3px solid transparent;
                    }
                    .show__slide {
                        border-color: #0f6a3e;
                    }
                }
            }
            .poduct__detail__sell {
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                font-size: 1rem;
                color: #757575;
                .left__align {
                    flex-basis: 120px;
                    width: 120px;
                }
                .space__top {
                    margin-top: 25px;
                    padding: 0 20px;
                }
                .poduct__header {
                    margin: 0;
                    font-size: 1.5rem;
                    line-height: 1.75rem;
                    font-weight: 500;
                    font-size: rgba(0, 0, 0, 0.8);
                    overflow-wrap: break-word;
                }
                .poduct__review__wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 0.625rem;
                    font-size: 1rem;
                    .review__item {
                        padding-right: 0.75rem;
                        color: #757575;
                        &:not(:first-child) {
                            padding-left: 0.75rem;
                            border-left: 1px solid rgba(0, 0, 0, 0.14);
                        }
                        .review__number {
                            margin-right: 5px;
                            font-weight: 500;
                        }
                    }
                    .product__rate {
                        display: flex;
                        align-items: center;
                        color: #fb6e2e;
                        svg {
                            margin-left: 5px;
                        }
                    }
                }
                .product__price {
                    margin-top: 10px;
                    padding: 15px 20px;
                    background: #fafafa;
                    span {
                        min-height: 1.875rem;
                        font-size: 1.875rem;
                        font-weight: 500;
                        color: #d0021c;
                    }
                }
                .product__document {
                    .document__item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                    }
                    .download__document svg {
                        font-size: 1.5rem;
                        color: #2ea16a;
                    }
                }
                .product__quantity__wrapper {
                    display: flex;
                    align-items: center;
                    .product__quantity__mid {
                        display: flex;
                        margin-right: 15px;
                        button {
                            padding: 0;
                            justify-content: center;
                            width: 32px;
                            height: 32px;
                            font-size: 1rem;
                            font-weight: 300;
                            background: transparent;
                            color: rgba(0, 0, 0, 0.8);
                            border: 1px solid rgba(0, 0, 0, 0.09);
                            border-radius: 2px;
                            &:first-child {
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
                            &:last-child {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                        .input__quantity {
                            justify-content: center;
                            text-align: center;
                            width: 50px;
                            height: 32px;
                            font-size: 1rem;
                            font-weight: 400;
                            color: rgba(0, 0, 0, 0.8);
                            border: 1px solid rgba(0, 0, 0, 0.09);
                            border-left: 0;
                            border-right: 0;
                            border-radius: 0;
                            cursor: text;
                        }
                    }
                }
                .product__buy__wrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-top: 15px;
                    padding-left: 20px;
                    button {
                        margin-top: 15px;
                        min-width: 80px;
                        max-width: 250px;
                        font-size: 16px;
                        line-height: 1;
                        height: 48px;
                        padding: 0 20px;
                        outline: 0;
                    }
                    .button__cart {
                        background: rgba(15, 106, 62, 0.1);
                        color: rgb(15, 106, 62);
                        border: 1px solid #0f6a3e;
                        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.03);
                        margin-right: 15px;
                        svg {
                            margin-right: 10px;
                        }
                    }
                    .button__buy {
                        svg {
                            font-size: 25px;
                            margin-right: 10px;
                        }
                        color: #fff;
                        background: #2ea16a;
                    }
                }
                .promotion__wrapper {
                    width: 100%;
                    display: flex;
                    .promotion {
                        width: calc(100% - 120px);
                        min-width: 0;
                        overflow-wrap: break-word;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1.5;
                    }
                }
            }
        }
        .product__detail__bottom {
            margin: 1rem -0.75rem 0;
            .tab__header {
                position: sticky;
                top: 46.5px;
                z-index: 2;
            }
            .tab__item {
                height: 3.75rem;
                background: #fff;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                .nav-link {
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    max-height: 3.75rem;
                    padding: 0;
                    font-size: 1rem;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                    transition: all 0.5s;
                    &:active,
                    &:hover {
                        outline: none;
                        color: #09522f;
                    }
                    &.active {
                        outline: none;
                        color: #09522f;
                    }
                }
                &.top {
                    flex-wrap: nowrap;
                    padding-left: 1.2rem;
                    .nav-item {
                        position: relative;
                        height: 3.75rem;
                        display: flex;
                        align-items: center;
                        margin-right: 1.5rem;
                        .nav-link {
                            text-align: center;
                            &.active {
                                outline: none;
                                color: #09522f;
                                &::after {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    width: 100%;
                                    height: 3px;
                                    background-color: #09522f;
                                }
                            }
                        }
                    }
                }
                &.bottom {
                    justify-content: center;
                    .nav-link {
                        &:active,
                        &:hover {
                            font-weight: 700;
                        }
                        &.active {
                            font-weight: 700;
                        }
                    }

                    .tab__bottom__content {
                        display: block;
                        position: relative;
                        margin: 0 1.5rem;
                        width: 100%;
                        .swiper-slide {
                            width: fit-content;
                        }
                        .swiper-button-next-tab,
                        .swiper-button-prev-tab {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            top: 0;
                            z-index: 2;
                            width: 1.5rem;
                            height: 100%;
                            font-size: 1.5rem;
                        }
                        .swiper-button-next-tab {
                            right: -1.5rem;
                            left: auto;
                        }
                        .swiper-button-prev-tab {
                            left: -1.5rem;
                            right: auto;
                        }
                        .swiper-button-disabled {
                            visibility: hidden;
                        }
                    }
                }
            }
            .tab__content {
                background-color: #fff;
                .content__wrapper {
                    padding: 0 1.2rem 1.2rem;
                    min-height: 20vh;
                    overflow: hidden;
                    .part__content {
                        padding: 1rem 0;
                        h4 {
                            font-size: 1.2rem;
                        }
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                    }
                    .reviews__header {
                        padding: 1rem 0;
                        margin: 0;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                        .reviews__header__left {
                            text-align: center;
                            color: #09522f;
                            h4 {
                                font-size: 1.2rem;
                            }
                            .number__rating__stars {
                                font-size: 1.5rem;
                                font-weight: 700;
                            }
                            .total__rating__stars {
                                margin-top: 0.5rem;
                                .rating__stars {
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 1.2rem;
                                }
                            }
                        }
                        .reviews__header__right {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            padding: 1rem 1rem 0;
                            .button__stars {
                                margin-right: 0.5rem;
                                margin-bottom: 0.5em;
                                font-size: 16px;
                                line-height: 1;
                                background: rgba(15, 106, 62, 0.1);
                                color: #0f6a3e;
                                border: 1px solid #0f6a3e;
                                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.03);
                            }
                        }
                    }
                    .reviews__content {
                        display: flex;
                        padding: 1rem 0;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                        .reviews__avatar {
                            width: 2.5rem;
                            margin-right: 1rem;
                            text-align: center;
                            .reviews__user__avatar {
                                width: 2.5rem;
                                height: 2.5rem;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 50%;
                                }
                            }
                        }
                        .reviews__product__wrapper {
                            .reviews__product__user {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 1rem;
                                color: rgba(0, 0, 0, 0.87);
                                .reviews__product__time {
                                    color: rgba(0, 0, 0, 0.54);
                                }
                            }
                            .reviews__product__stars {
                                margin-top: 0.375rem;
                            }
                            .reviews__product__content {
                                margin-top: 1rem;
                                color: rgba(0, 0, 0, 0.87);
                            }
                        }
                    }
                }
            }
        }
        .require {
            margin: 1rem -0.75rem 0;
            .section__content {
                padding: 1.2rem;
                font-weight: 500;
                color: #757575;
                .receiver {
                    font-size: 1rem;
                }
                .message {
                    margin-top: 1.2rem;
                    padding: 0.75rem;
                    border: 1px solid #0f6a3e;
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.03);
                }
                .info {
                    display: flex;
                    justify-content: space-between;
                    input {
                        margin-top: 1.2rem;
                        width: 100%;
                        border: 1px solid #0f6a3e;
                        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.03);
                    }
                }
                .button__wrapper {
                    display: flex;
                    justify-content: left;
                    .send__message {
                        justify-content: center;
                        margin-top: 1.2rem;
                        padding: 0 20px;
                        min-width: 70px;
                        max-width: 250px;
                        height: 35px;
                        font-size: 16px;
                        line-height: 1;
                        color: #fff;
                        background-color: #2ea16a;
                    }
                }
            }
        }
        .box__hotlines{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .box__hotlines__left{
                padding-left: 1rem;
                h3{
                    font-size: 1.2rem;
                    line-height: 1.75rem;
                    font-weight: 500;
                }
                h2{
                    a{
                        font-size: 1.5rem;
                        color: #2ea16a;
                    }
                }
            }
            .box__hotlines__right{
                padding: 0.5rem;
                display: flex;
                align-items: center;
                a {
                    display: block;
                    height: 55px;
                    width: 55px;
                    border: 3px solid #eaf2de;
                    border-radius: 50%;
                    position: relative;
                    svg{
                        font-size: 32px;
                        color: #2ea16a;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                
            }
        }
    }
}

.modal__image__product {
    .modal__large__image {
        min-height: 100px;
        width: 100%;
        position: relative;
        img {
            width: 100%;
            max-height: 85vh;
            object-fit: cover;
            object-position: center;
        }
        .modal__arow__prev,
        .modal__arow__next {
            position: absolute;
            top: 50%;
            width: 2.5rem;
            height: 5rem;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translate3d(0, -2.5rem, 0);
            background: rgba(0, 0, 0, 0.54);
            font-size: 2.1875rem;
        }
        .modal__arow__prev {
            left: 0;
        }
        .modal__arow__next {
            right: 0;
        }
    }
    .modal__poduct__name {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.0625rem;
        color: #000;
        word-wrap: break-word;
        line-height: 1.125rem;
        height: 2.25rem;
        padding-right: 0.75rem;
    }

    .modal__thumb__image {
        margin-top: 1.25rem;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        .item__thumb {
            margin-bottom: 0.75rem;
            margin-right: 0.75rem;
            .slide {
                border: 3px solid transparent;
                &.show__slide {
                    border-color: #0f6a3e;
                }
            }

            img {
                width: 80px;
                height: 80px;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

@include responsive("xs") {
    .product__detail {
        .product__style__content {
            .poduct__detail__top {
                .poduct__detail__image {
                    display: flex;
                    .large__image {
                        width: 75%;
                    }
                    .thumbs__image {
                        width: 25%;
                        .image__swiper {
                            padding: 0 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}

@include responsive("sm") {
    .product__detail {
        .product__style__content {
            .poduct__detail__top {
                .poduct__detail__image {
                    .large__image {
                        width: 65%;
                    }
                    .thumbs__image {
                        width: 35%;
                        .image__swiper {
                            .swiper-slide {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include responsive("md") {
    .product__detail {
        .product__style__content {
            .poduct__detail__top {
                margin-left: 0px;
                margin-right: 0px;
                .poduct__detail__image {
                    display: block;
                    .large__image {
                        width: 100%;
                    }
                    .thumbs__image {
                        width: 100%;
                        .image__swiper {
                            padding: 10px 0;
                            .swiper-slide {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .product__detail__bottom {
                margin-left: 0px;
                margin-right: 0px;
                .tab__header {
                    top: 0px;
                    .top {
                        .nav-link {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
            .require {
                margin: 1rem 0;
            }
        }
    }
}

@include responsive("lg") {
    .modal__image__product {
        .modal-lg {
            --bs-modal-width: 850px;
        }
    }
}
