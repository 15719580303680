.product__item {
    display: flex;
    flex-direction: column;
    text-align: center;
    a {
        text-decoration: none;
        border: 1px solid transparent;
        border-radius: 0.125rem;
        .product__image {
            position: relative;
            &::before {
                content: '';
                width: 50%;
                height: 100%;
                position: absolute;
                top: 0;
                left: -75%;
                display: block;
                background: linear-gradient(to right,rgba(255,255,255,0) 0,rgba(255,255,255,.3) 100%);
                transform: skewX(-17deg);
            }
            min-height: 120px;
            aspect-ratio: 1 / 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.125rem;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                min-height: 185px;
                max-height: 250px;
            }
        }
        .product__content {
            padding: 15px 0;
            .product__name {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.54);
                margin-bottom: 8px;
                line-height: 21px;
            }
            .product__price {
                color: #d0021c;
                font-weight: bold;
                line-height: 18px;
                font-size: 18px;
                margin-bottom: 8px;
            }
            .product__rate{
                b {
                    color: #fb6e2e;
                    display: inline-block;
                    vertical-align: middle;
                }
                svg {
                    color: #fb6e2e;
                    margin: 0 0 2px 5px;
                }
            }
        }
        &:hover .product__image::before {
            left: 125%;
            transition: 1s all;
        }
        &:hover {
            .product__content {
                .product__name {
                    color: #09522f;
                }
            } 
        }
    }
}




 