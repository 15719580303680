.admin__main {
    .admin__brand{
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            display: flex;
            align-items: center;
            color: white;
            .website__logo{
                width: 36px;
                height: 36px;
                margin-right: 1rem;
                img{
                   width: 100%; 
                   height: 100%;
                }
            }
            .website__name{
                white-space: nowrap;
            }  
        }
    }
    .admin__header {
        display: flex;
        align-items: center;
        justify-content: right;
        #dropdown-info {
            border: none;
            font-size: 1.2rem;
            background: transparent;
            color: #ffffffa6;
            &:hover {
                color: white;
            }
        }
    }
    .admin__content{
        padding: 1rem;
        h2{
            text-align: center;
            margin-bottom: 1rem;
        }
    }
    .ant-space-item:first-child{
        width: 100%;
    }
    .anticon{
        vertical-align: 0;
    }
}
