.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    .button__pagination{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        min-width: 2.5rem;
        height: 1.875rem;
        font-size: 1.25rem;
        margin-left: 0.9375rem;
        margin-right: 0.9375rem;
        color: rgba(0, 0, 0, 0.54);
        &.active,
        &:hover{
            color: #fff;
            background-color: #0f6a3e;
        }
        &.button__left {
            margin-left: 0;
            margin-right: 0.9375rem;
        }
        &.button__right {
            margin-left: 0.9375rem;
            margin-right: 0;
        }
    }
    
}