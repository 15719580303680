.rating__stars{
    display: flex;
    font-size: 0.8rem;
    .rating__stars__warpper{
        position: relative;
        margin-right: 1px;
        
        .rating__stars__lit{
            overflow: hidden;
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            svg {
                color: #fb6e2e;
                display: block;
                position: absolute;
                left: 0; 
            }
        }
        svg {
            color: #fb6e2e;
            display: block;
        }
    }
}