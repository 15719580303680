.section__simple {
    .section__header {
        display: flex;
        align-items: center;
        background: #fff;
        height: 3.75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 0 1.25rem;
        .section__header__title {
            text-transform: uppercase;
            font-size: 1rem;
            color: #09522f;
            /* color: rgba(0, 0, 0, 0.54); */
            font-weight: 500;
        }
    }
}

.section__content{
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

@include responsive("sm") {
    .section__simple {
        .section__header {
            .section__header__title {
                font-size: 1.2rem; 
            }
        }
    }
}