.about {
    // margin-top: 1.25rem;
    background-color: white;
    margin-top: 1.25rem;
    .about__content {
        padding: 0;
        .carousel-indicators {
            bottom: 27px;
            flex-direction: row;
            left: 0;
            right: 0;
            top: auto;
            margin: 0;
        }
        .carousel-item {
            .about__left {
                position: absolute;
                left: 0;
                padding: 2em;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                z-index: 1;
                .carousel-caption {
                    box-shadow: 0 0px 3px 2px rgba(0, 0, 0, 0.05);
                    background-color: rgba(255, 255, 255, 0.7);
                    border-radius: 8px;
                    padding: 2em;
                    width: 100%;
                    position: static;
                    text-align: left;
                    h3 {
                        font-size: 28px;
                        line-height: 38px;
                        margin-bottom: 0.5rem;
                        color: #060606;
                        font-weight: 700;
                    }
                    .caption__content {
                        font-size: 16px;
                        line-height: 26px;
                        // color: #707070;
                        color: #09522f;
                    }
                }
            }
            .about__right {
                padding: 0;
            }
            .carousel__image {
                border-top-left-radius: 20px;
                overflow: hidden;
                width: 100%;
                height: 600px;
                filter: brightness(90%);
                img {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .carousel-control-next,
        .carousel-control-prev {
            .carousel-control-next-icon,
            .carousel-control-prev-icon {
                height: 2rem;
                border-radius: 2px;
            }
            top: auto;
            bottom: 19px;
            width: 15%;
            visibility: hidden;
        }
    }
}

@include responsive("sm") {
    .about {
        .about__content {
            .carousel-item {
                .about__left {
                    .carousel-caption {
                        h3 {
                            font-size: 32px;
                            line-height: 42px;
                            margin-bottom: 1.5rem;
                        }
                        .caption__content {
                            font-size: 17px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
}

@include responsive("md") {
    .about {
        .about__content {
            padding-top: 1.5rem;
            padding-right: 0;
            .carousel-indicators {
                width: calc((100% / 12) * 7);
                left: auto;
            }
            .carousel-item {
                .about__left {
                    left: 5em;
                    .carousel-caption {
                        background-color: rgba(245, 245, 245, 0.63);
                        h3 {
                            font-size: 35px;
                            line-height: 45px;
                        }
                    }
                }
            }
            .carousel-control-next,
            .carousel-control-prev {
                z-index: 3;
                visibility: visible;
            }
            .carousel-control-prev {
                left: calc((100% / 12) * 5);
            }
        }
    }
}

@include responsive("lg") {
    .about {
        .about__content {
            .carousel-item {
                .about__left {
                    .carousel-caption {
                        h3 {
                            font-size: 40px;
                            line-height: 50px;
                        }
                        .caption__content {
                            font-size: 19px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
}

@include responsive("xl") {
    .about {
        .about__content {
            .carousel-item {
                .about__left {
                    left: 10em;
                    .carousel-caption {
                        h3 {
                            font-size: 50px;
                            line-height: 60px;
                        }
                        .caption__content {
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }
}
