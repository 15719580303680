.login-admin {
    background: #222d32;
    font-family: "Roboto", sans-serif;
    min-height: 100vh;

    .login-box {
        margin-top: 75px;
        height: auto;
        background: #1a2226;
        text-align: center;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .login-key {
        height: 100px;
        font-size: 80px;
        line-height: 100px;
        // add linearGradient for svg
        svg * {
            fill: url(#lgrad);
        }
    }

    .login-title {
        margin-top: 15px;
        text-align: center;
        font-size: 30px;
        letter-spacing: 2px;
        margin-top: 15px;
        font-weight: bold;
        color: #ecf0f5;
    }

    .login-form {
        margin-top: 25px;
        text-align: left;
        padding: 0 30px;
    }

    input {
        background-color: #1a2226;
        border: none;
        border-bottom: 2px solid #0db8de;
        border-top: 0px;
        border-radius: 0px;
        font-weight: bold;
        outline: 0;
        padding-left: 0px;
        color: #ecf0f5;
    }

    .form-group {
        margin-bottom: 40px;
        outline: 0px;
    }

    .form-control {
        background-color: #1a2226;
        border: none;
        border-bottom: 2px solid #0db8de;
        border-top: 0px;
        border-radius: 0px;
        font-weight: bold;
        outline: 0;
        margin-bottom: 20px;
        padding-left: 0px;
        color: #ecf0f5;
    }

    .ant-input-suffix {
        .anticon.ant-input-password-icon {
            color: #6c6c6c;
        }
    }

    .form-control:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-bottom: 2px solid #0db8de;
        outline: 0;
        background-color: #1a2226;
        color: #ecf0f5;
    }

    input:focus {
        outline: none;
        box-shadow: 0 0 0;
    }

    label {
        margin-bottom: 0px;
    }

    .form-control-label {
        font-size: 10px;
        color: #6c6c6c;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .btn-outline-primary {
        white-space: nowrap;
        vertical-align: middle;
        color: #0db8de;
        border-radius: 0px;
        font-weight: bold;
        letter-spacing: 1px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border: 1px solid #0db8de;
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        line-height: 1.25;
    }

    .btn-outline-primary:hover {
        background-color: #0db8de;
        right: 0px;
    }

    .login-button {
        display: flex;
        justify-content: right;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 25px;
        padding-left: 15px;
    }

    .login-text {
        width: 100%;
        text-align: left;
        padding-left: 0px;
        padding-right: 15px;
        color: #a2a4a4;
    }

    .loginbttm {
        padding: 0px;
    }
}


