.header__wrap {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    // background: transparent;
    background: rgba(0, 0, 0, 0.03);
    transition: all ease-in 0.5s;
    &.nav__fixed {
        background-color: #fff;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        button {
            color: rgba(0, 0, 0, 0.45);
        }
    }
    a {
        margin: auto;
        font-weight: 700;
        color: white;
        transition: all 0.5s;
        outline: 0;
        &:focus,
        &:hover {
            color: #15f8ed;
        }
    }
    svg {
        display: block;
        margin: auto;
        font-size: 22px;
    }
    button {
        color: #fff;
        background-color: transparent;
        border: none;
        outline: none;
        &:hover,
        &:active,
        &:first-child:active {
            background-color: transparent;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .navbar__top {
        .vertical:not(:first-child):after {
            content: "";
            height: 0.9375rem;
            width: 0;
            border-left: 1px solid rgba(255, 255, 255, 0.22);
            border-right: 1px solid rgba(255, 255, 255, 0.22);
            position: absolute;
            left: 0px;
            top: calc(50% - 7px);
        }
        a,
        span {
            font-size: 0.8125rem;
            font-weight: 300;
            padding: 0.25rem 0.5rem;
            position: relative;
            overflow: visible;
        }
        .hotline {
            color: #fb782ef5;
            font-weight: 700;
            &:hover {
                color: #ce0e0e;
            }
        }
        .navbar__top__content {
            height: 2.125rem;
            padding: 8px 0;
            .navbar__top__left {
                a:first-child {
                    padding-left: 0;
                }
            }
            .navbar__top__right {
                a:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    .header__main {
        padding: 0;
        .navbar__main {
            padding: 0;
            display: block;
            position: relative;
            .navbar__search {
                display: flex;
                flex-wrap: inherit;
                justify-content: space-between;
                align-items: center;
                padding: 0 5px;
                width: 100%;
                .navbar__brand {
                    align-items: center;
                    .website__logo {
                        width: 36px;
                        height: 36px;
                        margin-right: 1rem;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .website__name {
                        white-space: nowrap;
                    }
                }
                .search__wrap {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 75%;
                    .input__search {
                        height: 32px;
                        font-size: 1rem;
                        color: #000;
                        background-color: #fff;
                        border: solid 1px rgba(137, 137, 137, 0.4);
                        border-radius: 10em;
                        transition: all 0.5s;
                        cursor: pointer;
                    }
                    .button__search__wrapper {
                        position: absolute;
                        top: 0px;
                        right: 2px;
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                    .button__search {
                        padding: 6px 12px;
                        font-size: 0.8rem;
                        border-radius: 10em;
                        color: rgba(0, 0, 0, 0.45);
                        transition: all 0.5s;
                        svg {
                            font-size: 16px;
                        }
                        &:hover,
                        &:active {
                            background-color: #0f6a3e;
                            color: #fff;
                        }
                    }
                    .search__result__wrapper {
                        position: absolute;
                        inset: 0px auto auto 0px;
                        transform: translate3d(0px, 35px, 0px);
                        width: 100%;
                        z-index: 2000;
                        .search__result {
                            width: 100%;
                            border: 1px solid rgba(137, 137, 137, 0.4);
                            background-color: rgb(255, 255, 255);
                            border-radius: 5px;
                            .search__result__title,
                            .search__result__entry {
                                color: #09522f;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                line-height: 1rem;
                                padding: 0.625rem;
                                display: block;
                                font-weight: 600;
                            }
                            .search__result__entry {
                                font-weight: normal;
                                &:hover {
                                    background-color: #fafafa;
                                }
                            }
                        }
                    }
                }
                .button__cart {
                    margin-left: 5px;
                    font-size: 1rem;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    transition: all 0.5s;
                }
                .button__menu,
                .navbar-toggler {
                    margin: 0 5px;
                    padding: 0;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            .navbar__menu {
                position: relative;
                padding: 0;
                height: auto;
                background: transparent;
                overflow: visible;
                .menu__product__title,
                a {
                    text-transform: uppercase;
                    margin: auto;
                    padding: 8px 12px;
                    font-weight: 600;
                    transition: all 0.5s;
                    line-height: 1.2;
                }
                .menu__product {
                    border: 1px solid transparent;
                    cursor: pointer;
                    .menu__product__title {
                        display: flex;
                        align-items: center;
                        color: #fff;
                        span {
                            display: inline-block;
                            margin-left: 5px;
                        }
                    }
                    .menu__product__content {
                        // display: none;
                        display: block;
                        position: absolute;
                        inset: 0px auto auto 0px;
                        transform: translate3d(0px, 34px, 0px);
                        width: 30%;
                        min-height: calc(100vh - 117px);
                        z-index: 3;
                        padding: 1rem 0;
                        background: rgba(46, 161, 105, 0.85);
                        border: 1px solid rgba(46, 161, 105, 1);
                        a {
                            display: block;
                            width: 100%;
                            margin: 0;
                            text-transform: uppercase;
                            color: #fff;
                        }
                        .menu__item {
                            box-shadow: -2px 1px 5px transparent;
                            border: 1px solid transparent;
                            padding: 0.3rem 0 0.3rem 0.8rem;
                            .title__list {
                                display: flex;
                                justify-content: space-between;
                                color: #fff;
                            }
                            .one__list {
                                display: none;
                                position: absolute;
                                top: 0;
                                left: 100%;
                                width: 70%;
                                min-height: calc(100vh - 117px);
                                z-index: 4;
                                padding: 1rem 0;
                                border-radius: 4px;
                                background: rgba(46, 161, 105, 0.85);
                                box-shadow: 0 1px 5px #e7e7e7;
                                border: 1px solid rgba(46, 161, 105, 1);
                                .one__list__item {
                                    padding-left: 1rem;
                                    &:hover {
                                        background: rgba(221, 225, 225, 0.878);
                                        a {
                                            color: #09522f;
                                        }
                                    }
                                }
                            }
                            &:hover {
                                background: #e9ebeb9c;
                                box-shadow: -2px 1px 5px #e7e7e7;
                                border: 1px solid #e7e7e7;
                                .title__list {
                                    color: #09522f;
                                    a {
                                        color: #09522f;
                                    }
                                }
                            }
                            &:hover .one__list {
                                display: block;
                            }
                        }
                    }
                    &:hover {
                        border: 1px solid rgba(46, 161, 105, 1);
                        border-bottom: 1px solid transparent;
                        .menu__product__title {
                            color: #15f8ed;
                        }
                        .menu__product__content {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.header__bottom {
    background: white;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    .navbar__bottom {
        padding: 0;
        .link__wrapper {
            padding: 0.3rem 0;
        }
        a {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.8125rem;
            color: rgba(0, 0, 0, 0.54);
            svg {
                font-size: 1.3rem;
            }
            &:hover,
            &:active {
                outline: none;
                color: #0f6a3e;
            }
        }
        .active {
            color: #0f6a3e;
        }
    }
}

.mobile__menu {
    --bs-offcanvas-width: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 1);
    transition: all 0.5s ease-out;
    .offcanvas-header {
        justify-content: normal;
        color: #0f6a3e;
        border-bottom: 1px solid #e7e7e7;
        svg {
            font-size: 1rem;
            margin-left: 0.5rem;
            margin-right: 1.5rem;
        }
        .offcanvas-title {
            text-transform: uppercase;
        }
    }
    .menu__product {
        padding: 0;
        a {
            text-transform: uppercase;
            width: 100%;
            color: rgba(0, 0, 0, 0.45);
            padding: 8px 12px;
            font-weight: 700;
            transition: all 0.5s;
            &:hover {
                color: #0f6a3e;
            }
            line-height: 1.5;
        }
        svg {
            font-size: 1.2rem;
        }
        .menu__product__content {
            padding: 0;
            width: 100%;
            .menu__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: -2px 1px 5px transparent;
                border-bottom: 1px solid #e7e7e7;
                transition: all 0.5s;
                &:hover {
                    background: rgb(255, 255, 255);
                    box-shadow: -2px 1px 5px #e7e7e7;
                    a {
                        color: #0f6a3e;
                    }
                }
            }
        }
    }
}

.pull__right__bar {
    float: right;
    position: fixed;
    right: 0px;
    top: 50vh;
    width: 70px;
    z-index: 1001;
    text-align: center;
    .toggle-right-sidebar {
        display: block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 50px;
        margin: auto;
        scale: 0.8;
        font-size: 1.2rem;
        color: #e6ebff;
        background: rgb(13, 19, 38);
        cursor: pointer;
        opacity: 0.5;
        svg {
            display: block;
            margin: auto;
            height: 100%;
        }
    }
}

.right__sidebar {
    width: 70px;
    position: fixed;
    z-index: 1000;
    right: 0px;
    top: 50vh;
    transition: all 0.5s ease-in-out;
    overflow-y: auto;
    &.hide_right_bar {
        margin-right: -300px !important;
    }
    .right__sidebar__content {
        padding: 40px 5px 10px;
        padding-top: 50px;
        // background: #0d1326;
        background-color: transparent;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        .right__sidebar__wrap {
            // border-top: 1px solid #41414d;
            padding-top: 5px;
            .right__sidebar__item {
                display: block;
                color: rgba(230, 235, 255, 0.6);
                text-decoration: none;
                font-size: 1.2rem;
                line-height: 24px;
                margin-bottom: 5px;
                text-align: center;
                cursor: pointer;
                .right__sidebar__icon {
                    scale: 0.8;
                    background: rgba(13, 19, 38, 0.5);
                    display: block;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    border-radius: 50px;
                    margin: auto;
                    overflow: hidden;
                    font-size: 1.2rem;
                    svg {
                        display: block;
                        margin: auto;
                        height: 100%;
                    }
                    &.facebook {
                        font-size: 1.5rem;
                    }
                    &.zalo {
                        font-size: 1.8rem;
                    }
                }
                &:hover svg {
                    color: rgba(230, 235, 255, 1);
                }
            }
        }
    }
}

@include responsive("md") {
    .header__wrap {
        position: static;
        svg {
            display: inline;
            font-size: 16px;
        }
        background: linear-gradient(to right, #0f6a3e 0%, #2ea16a 100%);
        .header__main {
            --bs-gutter-x: 1.5rem;
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            .navbar__main {
                .navbar__search {
                    padding: 8px 0px;
                    .search__wrap {
                        width: 80%;
                        position: relative;
                        .button__search {
                            padding: 6px 20px;
                            color: white;
                            background: #2ea16a;
                            &:hover,
                            &:active {
                                background-color: #157347;
                            }
                        }
                    }
                    .button__cart {
                        color: #fff;
                        svg {
                            display: block;
                            font-size: 24px;
                            margin: auto;
                        }
                        &:hover {
                            color: #15f8ed;
                        }
                    }
                }
            }
        }
    }
    .pull__right__bar {
        width: 90px;
        .toggle-right-sidebar {
            scale: 1;
        }
    }
    .right__sidebar {
        width: 90px;
        .right__sidebar__content {
            .right__sidebar__wrap {
                padding-top: 10px;
                .right__sidebar__item {
                    margin-bottom: 10px;
                    .right__sidebar__icon {
                        scale: 1;
                    }
                }
            }
        }
    }
}
