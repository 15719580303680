* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-size: 1rem;
    color: #757575;
    background-color: #f5f5f5;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn{
    display: flex;
    align-items: center;
    color: #000;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow:none;
    &:active,
    &:hover,
    &:first-child:active:focus-visible,
    &:focus-visible {
        box-shadow:none; 
        outline: none;
    }
}

.form-control{
    display: flex;
    align-items: center;
    color: #000;
    border: none;
    outline: none;
    box-shadow:none;
    &:active,
    &:hover,
    &:first-child:active:focus-visible,
    &:focus-visible {
        box-shadow:none; 
        outline: none;
    }
    background-color: transparent;
}

a,
.nav-link {
    text-decoration: none;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.54);
    word-wrap: break-word;
    outline: 0;
    transition: all .5s;
    &:hover,
    &:active{
        outline: 0;
        color: #09522f;
    }
}

.notify {
    padding: 15px;
    text-align: center;
    color: #09522f;
    font-weight: bolder;
    font-size: 1rem;
}
