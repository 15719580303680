.simple__tabs {
    .simple__tabs__wrapper {
        justify-content: space-between;
        position: relative;
        background: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        .nav-link {
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-height: 3.75rem;
            padding: 0;
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.54);
            font-weight: 500;
            transition: all 0.5s;
            &:active,
            &:hover {
                outline: none;
                color: #09522f;
                font-weight: 700;
            }
            &.active {
                outline: none;
                color: #09522f;
                font-weight: 700;
            }
        }
        .simple__tabs__content {
            display: block;
            width: 100%;
            max-width: 70%;
            height: 3.75rem;
            .swiper {
                position: relative;
                padding: 0 1.5rem;
                .swiper-slide {
                    width: auto;
                    height: 3.75rem;
                }
                .swiper-button-prev,
                .swiper-button-next {
                    top: 0;
                    margin: 0;
                    width: 1.5rem;
                    height: 100%;
                    border-radius: 0;
                    background-color: #fff;
                    box-shadow: none;
                    opacity: 1;
                }
                .swiper-button-prev {
                    left: -5px;
                    right: auto;
                }
                .swiper-button-next {
                    right: -5px;
                    left: auto;
                }
                .swiper-button-disabled {
                    visibility: hidden;
                }
            }
            .select__price {
                overflow: visible;
                display: flex;
                align-items: center;
                height: 3.75rem;
                span {
                    margin-right: 10px;
                    vertical-align: middle;
                }
                svg {
                    font-size: 1.2rem;
                }
            }   
        }
        .simple__tabs__paging {
            display: flex;
            align-items: center;
            font-size: 1.5rem;
            svg {
                cursor: pointer;
            }
            span {
                font-size: 1rem;
                font-weight: 500;
            }
        }
        .child__tab {
            display: block;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 0.8rem 0.8rem 0.8rem;
            z-index: 2;
            background: #fff;
            color: rgba(0, 0, 0, 0.54);
            font-weight: 500;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
            border-radius: 2px;
            border: 0;
            .child__tab__item {
                height: 2.125rem;
                line-height: 2.125rem;
                cursor: pointer;
                &:hover {
                    color: #09522f;
                    font-weight: 700;
                }
            }
        }
    }
}

@include responsive("xs") {
    .simple__tabs {
        .simple__tabs__wrapper {
            .simple__tabs__content {
                max-width: 78%;
            }
        }
    }
}

@include responsive("sm") {
    .simple__tabs {
        .simple__tabs__wrapper {
            .simple__tabs__paging {
                span {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@include responsive("md") {
    .simple__tabs {
        .simple__tabs__wrapper {
            .simple__tabs__content {
                max-width: 83%;
            }
        }
    }
}
