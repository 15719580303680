.swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-button-prev,
    .swiper-button-next {
        top: var(--swiper-navigation-top-offset, 50%);
        width: var(--swiper-navigation-size);
        height: var(--swiper-navigation-size);
        margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 5px 0 #0009;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s;
        scale: 0.75;
    }

    .swiper-button-prev{
        left: var(--swiper-navigation-sides-offset,0px);
    }

    .swiper-button-next {
        right: var(--swiper-navigation-sides-offset,0px);
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-weight: 600;
        font-size: 15px;
        color: #000;
    }
    &:hover .swiper-button-prev,
    &:hover .swiper-button-next {
        opacity: 1;
        scale: 0.9;
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
        opacity: 0;
    }
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
}

@include responsive("sm") {
    .swiper {
        .swiper-button-prev,
        .swiper-button-next {
            visibility: visible;
            opacity: 0.6;
        }
    }
}
