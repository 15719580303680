.category {
    margin-top: 1.25rem;
    .category__swiper {
        .category__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.87);
            border-right: 1px solid rgba(0, 0, 0, 0.05);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            text-align: center;
            background-color: #fff;
            &:hover {
                border-color: rgba(0, 0, 0, 0.12);
                box-shadow: 0 0 0.8125rem 0 rgba(0, 0, 0, 0.05);
            }
            .category__image {
                margin-top: 10%;
                width: 70%;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%;
                    min-height: 64px;
                }
            }
            .category__title {
                width: 100%;
                height: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    font-size: 1rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    }
}
