.footer {
    color: white;
    // margin-top: 30px;
    padding: 4rem 3rem 0 3rem;
    background: linear-gradient(to right,#0f6a3e 0%,#2ea16a 100%);
    // background: linear-gradient(to right,#09522f 0%,#2ea16a 100%);
    
    .footer__logo{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .footer__title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 25px;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
    }
    .footer__menu{
        list-style-type: none;
        margin: 0;
        padding: 0;
        li{
            font-size: .75rem;
            margin-bottom: 0.75rem;
            a {
                color: white;
                font-size: 0.8125rem;
                font-weight: 400;
                text-decoration: none;
                overflow: hidden;
                transition: all .5s;
                &:hover{
                    color: #15f8ed;
                }
                svg{
                    line-height: 0.75rem;
                }
                span{
                    display: inline;
                    vertical-align: middle;
                    margin-left: 5px;
                }
            }
            
        }
        .hotline a{
            color: #fb782ef5;
            font-weight: 700;
        }   
    }
    .footer__content{
        margin-bottom: 30px;
    }
}
.copyright{
    background: #09522f;
    text-align: center;
    color: #ffffffc3;
    padding: 10px;
}
