@mixin responsive($screen) {
    @if(map-has-key($map: $breakpoints, $key: $screen)){
        $value: map-get($map: $breakpoints, $key: $screen); 
        @media screen and (min-width: $value) {
             @content;
        }
    }
    @else{
        @warn "Sai ten man hinh";
    }
}