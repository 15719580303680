.banner {
    padding: 0;
    margin-top: 0;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0.125rem;
    overflow: hidden;
    .carousel {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
        border-radius: 0.125rem;
        min-height: 100px;
        .carousel-wapper {
            position: relative;
            // min-height: 100px;
            // max-height: 250px;
            height: 200px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    .banner__left {
        padding: 0;
        .carousel-caption {
            bottom: 0;
            left :0;
            width: 100%;
            padding-bottom: 2rem;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.7) 100%);
        }
        .carousel-indicators {
            align-items: center;
            height: 2rem;
            margin-bottom: 0;
        }
        .carousel-control-prev,
        .carousel-control-next {
            display: none;
        }

        .carousel-control-prev-icon {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }

        .carousel-control-next-icon {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
    }
    .banner__right {
        padding-left: 0.3125rem;
        .endow {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.87);
            display: block;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
            border-radius: 0.125rem;
            overflow: hidden;
            flex: 1 1 50%;
            .img__wapper {
                position: relative;
                width: 100%;
                height: 100%;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    min-height: 95px;
                }
            }
        }
        .endow:last-child {
            margin-top: 0.3125rem;
            border-bottom-right-radius: 2px;
            overflow: hidden;
        }
    }
    .producer {
        margin: 0.3125rem auto 0.3125rem;
        min-height: 55px;
        text-align: center;
        .producer__swiper {
            .swiper-slide {
                height: auto;
            }
        }
        .producer__item {
            width: 70%;
            aspect-ratio: 1 / 1;
            max-width: 150px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 0.125rem;
            overflow: hidden;
            transition: all 0.2s;
            img {
                width: 100%;
                height: 100%;
                min-height: 55px;
            }
            &:hover {
                transform: translateY(-0.0825rem);
            }
        }
    }
}

@include responsive("xs") {
    .banner {
        .banner__left {
            .carousel-caption {
                padding-bottom: 2.5rem;
            }
            .carousel-indicators {
                height: 2.5rem;
            }
        }
        .carousel {
            .carousel-wapper {
                // min-height: 200px;
                // max-height: 360px;
                height: 250px;
            }
        }
    }
}

@include responsive("sm") {
    .banner {
        .banner__left {
            .carousel-control-prev,
            .carousel-control-next {
                display: flex;
            }
        }
        .carousel {
            .carousel-wapper {
                // min-height: 200px;
                // max-height: 360px;
                height: 300px;
            }
        }
    }
}

@include responsive("md") {
    .banner {
        --bs-gutter-x: 1.5rem;
        padding-top: calc(var(--bs-gutter-x) * 0.5);
        .banner__left {
            padding-left: calc(var(--bs-gutter-x) * 0.5);
        }
        .carousel {
            .carousel-wapper {
                // min-height: 200px;
                // max-height: 360px;
                height: 330px;
            }
        }
    }
}

@include responsive("lg") {
    .banner {
        padding-top: 1.25rem;
    }
    .carousel {
        .carousel-wapper {
            // min-height: 200px;
            // max-height: 360px;
            height: 360px;
        }
    }
}
